import React from 'react'
import { Link } from 'gatsby'
import "./style.scss"


class Navi extends React.Component {
  render() {
	const { location, title } = this.props	

    return (
      <nav className="navbar fixed-top navbar-dark px-0">
        <div className="w-100 d-flex align-items-start justify-content-between">
			<Link className="text-center navbar-brand ml-2" to="/">
				<img className="mb-0" src="/img/white-logo-1.png" alt=""/>
			</Link>
		  	<button className="navbar-toggler mt-3 mt-md-4" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse bg-primary" id="navbarNav">
				<div className="w-100 h-100">
					<div className="row w-100 h-100">
						<div className="col-md-12 d-flex flex-column justify-content-center">
						<Link className="text-center navbar-brand" to="/">
							<img src="/img/white-logo.png" alt=""/>
						</Link>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<ul className="navbar-nav text-center mt-5 justify-content-center">
							<li className={
								location.pathname === '/' ? 'nav-item active' : 'nav-item'
								}>
								<Link to="/" className="nav-link">
								Home
								</Link>
							</li>
							<li className={
								location.pathname === '/about/'
									? 'nav-item active'
									: 'nav-item'
								}>
								<Link to="/about/" className="nav-link">
								About
								</Link>
							</li>
							<li className={
								location.pathname === '/services/'
									? 'nav-item active'
									: 'nav-item'
								}>
								<Link to="/services/" className="nav-link">
								Services
								</Link>
							</li>
							<li className={
								location.pathname === '/team/'
									? 'nav-item active'
									: 'nav-item'
								}>
								<Link to="/team/" className="nav-link">
								The Team
								</Link>
							</li>
							<li><hr/></li>
							<li className={
								location.pathname === '/article/'
									? 'nav-item active'
									: 'nav-item'
								}>
								<Link to="/article/" className="nav-link">
								<small>Articling</small>
								</Link>
							</li>
							<li className={
								location.pathname === '/contact/'
									? 'nav-item active'
									: 'nav-item'
								}>
								<Link to="/contact/" className="nav-link">
								<small>Contact</small>
								</Link>
							</li>
						</ul>
						</div>
					</div>
				</div>
				
          	</div>
          
        </div>
      </nav>
    )
  }
}

export default Navi
