import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="footer bg-secondary text-white ">
    <div className="container py-5">      
		<div className="row">
			<div className="col-md-12 mb-5">
				<img src="/img/white-logo.png" alt=""/>
			</div>		
		</div>	
		<div className="row py-5">
			<div className="col-12 mb-4">
				<Link className="mr-3" to="/contact/"><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></Link>
				<a target="_blank" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>
			</div>
			<div className="col-md-3">				
				15 Bedford Road<br/>
				Toronto, Ontario<br/>
				Canada<br/>
				M5R 2J7
			</div>
			<div className="col-md-3">
				T: 416.868.1755<br/>
				F: 416.868.1990
			</div>
		</div>	  

    </div>
	<div className="sub-footer bg-black w-100 py-3">
		<div className="container">      
			<div className="row text-center text-md-left">
				<div className="col-sm-8">
					<p className="mb-0">Copyright 2020 All rights reserved.</p>
				</div>
				<div className="col-sm-4 ">

				</div>
			</div>
		</div>
	</div>
  </div>
)

export default Footer
