import React from 'react'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'

const Meta = ({ site, title }) => {
  const siteTitle = get(site, 'title')
  title = title ? `${title} | ${siteTitle}` : siteTitle
  return (
    <Helmet
      title={title}
      meta={[
		  {name:'keywords', content:'Trial,Appeal,Criminal,Crime,police,Charge,Arrest,Charter,Lawyer,Law,Bail,Murder,Homicide,Sexual,Sexual assault,Robbery,CPSO,Domestic,Greenspan,Impaired,Driving,Dangerous driving,DUI,Gun,Weapon,Fraud,Forge,extortion,Discipline,Theft,Drugs,Assault,Extradition,Inquiry,Litigation'},
        { name: 'twitter:card', content: 'summary' },
        {
          name: 'twitter:site',
          content: `@${get(site, 'twitter')}`,
        },
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: get(site, 'description'),
        },
        {
          property: 'og:url',
          content: `${get(site, 'siteUrl')}/profile`,
        },
        {
          property: 'og:image',
          content: `${get(site, 'siteUrl')}/img/profile.jpg`,
        },
      ]}
    />
  )
}
export default Meta
